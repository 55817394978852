import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";


import Home from "./components/pages/Home";
import LoginPage from './components/pages/LoginPage';
import VerifyEmailOtp from './components/pages/VerifyEmailOtp';
import Dashboard from './components/layout/dashboard';
import RequestAccess from './components/pages/RequestedAccess';
import UserManagement from './components/pages/UserManagement';
import UserForm from './components/pages/UserForm';
import BlacklistManagement from './components/pages/BlacklistManagement';
import ProjectManagement from './components/pages/ProjectManagement';
import ProjectForm from './components/pages/ProjectForm';
import VariantManagement from './components/pages/VariantManagement';
import VariantForm from './components/pages/VariantForm';
import { Toaster } from "react-hot-toast";
import VariantManagementGuest from './components/pages/VariantManagementGuest';
import VariantFormGuest from './components/pages/VariantFormGuest';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/otp/phone" element={<VerifyEmailOtp />} />
          <Route path="/admin/dashboard" element={<Dashboard/>} >
              <Route path="request-access" element={<RequestAccess/>}/>
              <Route path="user-management" element={<UserManagement/>}/>
              <Route path="black-list-management" element={<BlacklistManagement/>}/>
              <Route path="project-management" element={<ProjectManagement/>}/>
              <Route path="variant-management" element={<VariantManagement/>}/>
          </Route>
          <Route path="/user-form" element={<UserForm/>}/>
          <Route path="/project-form" element={<ProjectForm/>}/>
          <Route path="/variant-form" element={<VariantForm/>}/>
          <Route path="/guest/project" element={<VariantManagementGuest/>}/>
          <Route path="/guest/variant-form" element={<VariantFormGuest/>}/>
        </Routes>
      </BrowserRouter>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            background: 'var(--color-base)',
            color: 'black',
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: 'var(--color-base)',
              secondary: 'black',
            },
          },
        }}
      />
    </>
  );
}

export default App;
